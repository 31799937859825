exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadeau-js": () => import("./../../../src/pages/cadeau.js" /* webpackChunkName: "component---src-pages-cadeau-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */),
  "component---src-pages-reponse-js": () => import("./../../../src/pages/reponse.js" /* webpackChunkName: "component---src-pages-reponse-js" */),
  "component---src-pages-save-the-date-js": () => import("./../../../src/pages/save-the-date.js" /* webpackChunkName: "component---src-pages-save-the-date-js" */)
}

